
import Api from "./Api";
import {errorHandler} from "./Api"

const resource = "/awards";

export default{
  get(start_date=null, end_date=null, limit=15){
    return Api.get(`${resource}?start_date=${start_date}&end_date=${end_date}&limit=${limit}`).catch(errorHandler)
  },

}