import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { ApiFactory } from "../api/ApiFactory";
import router from '../router';
const AuthAPI = ApiFactory.get("auth");

export default new Vuex.Store({
  state: {
    accessToken:  localStorage.getItem('access_token') ||  '',
    refreshToken : localStorage.getItem('refresh_token') || '',
    kioskIdent : localStorage.getItem('kiosk_ident') || '',
    kioskToken : localStorage.getItem('kiosk_token') || '',
    
    messages: [],
  },

  getters: {
    accessToken: (state) => state.accessToken,
    accessParsed: (state, getters) => getters.accessToken ? JSON.parse(atob(getters.accessToken.split('.')[1])) : null,
    accessExpires: (state, getters) => getters.accessParsed ? getters.accessParsed.exp: null,
    accessValid: (state,  getters) => getters.accessParsed ? getters.accessExpires - (Date.now() / 1000) > 1 : false,

    refreshToken: (state) => state.refreshToken,
    refreshParsed: (state, getters) => getters.refreshToken ? JSON.parse(atob(getters.refreshToken.split('.')[1])) : null,
    refreshExpires: (state, getters) => getters.refreshParsed ? getters.refreshParsed.exp: null,
    refreshValid: (state,  getters) => getters.refreshParsed ? getters.refreshExpires - (Date.now() / 1000) > 1 : false,

    kioskIdent: (state) => state.kioskIdent,
    kioskToken: (state) => state.kioskToken,
  },

  mutations: {
    setKiosk(state, {ident, token}){
      state.kioskIdent = ident;
      state.kioskToken = token;
      localStorage.setItem('kiosk_ident', ident || '');
      localStorage.setItem('kiosk_token', token || '');
    },

    setTokens(state, {access_token, refresh_token}){
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
      localStorage.setItem('access_token', access_token || '');
      localStorage.setItem('refresh_token', refresh_token || '');
    },

    addMessage(state, message, level){
      state.messages.push({
        message: message, 
        level: level != undefined ? level : 'info'
      })
    },

    addError(state, message){
      state.messages.push({
        message: message, 
        level: 'error'
      })
    },

    clearMessages(state){
      state.messages = []
    }
  },
  actions: {
    login (state, {username, password}) {
      return AuthAPI.login(username, password).then((data) => {
        if (data.response.status == 200){
          state.commit('setTokens', data.response.data);
          //state.commit('addMessage', `Welcome, ${username}`);
        }
        return data.message
      }); 
    },

    kiosk_login (state, {ident, token}) {
      return AuthAPI.kiosk_login(ident, token).then((data) => {
        if (data.response.status == 200){
          state.commit('setKiosk', {ident, token});
          state.commit('setTokens', data.response.data);
        }
        return data.message
      }); 
    },

    logout (state){
      if (state.getters.accessValid || state.getters.refreshValid){
        state.commit('setTokens', '', '');
        state.commit('addMessage', "You are now logged out");
        router.push('/login');
      }
    },

    refresh(state){
      return AuthAPI.refresh(state.getters.refreshToken).then((accessToken) => {
        if (accessToken != null && accessToken != ''){
          state.commit('setTokens', {
            access_token: accessToken,
            refresh_token: state.getters.refreshToken
          });
          return true;
        }else{
          return false;
        }
        

      });
    }

  },
  modules: {
  }
})
