// apiDiscount.js

import Api from "./Api";
import {errorHandler} from "./Api"

const resource = "/family";

export default{
  async get_all(){
    return Api.get(`${resource}`).catch(errorHandler)
  },

  async get(discountId){
    return Api.get(`${resource}/${discountId}`).catch(errorHandler)
  },

  async create(payload){
    return Api.post(`${resource}`, payload).catch(errorHandler)
  },

  async update(payload){
    return Api.put(`${resource}/${payload.id}`, payload).catch(errorHandler)
  },

  async delete(discountId){
    return Api.delete(`${resource}/${discountId}`).catch(errorHandler)
  },

  getBalances(familyId, start_date=null, end_date=null){
    var url = `${resource}/${familyId}/balances`
    
    if (start_date != null && end_date != null){
      url += `?start_date=${start_date}&end_date=${end_date}` 

    }else if (start_date != null){
      url += `?start_date=${start_date}` 

    }else if (end_date != null){
      url += `?end_date=${end_date}` 
    }

    return Api.get(url).catch(errorHandler)
  },

  getPayments(familyId, start_date=null, end_date=null){
    var url = `${resource}/${familyId}/payments`

    if (start_date != null && end_date != null){
      url += `?start_date=${start_date}&end_date=${end_date}` 

    }else if (start_date != null){
      url += `?start_date=${start_date}` 

    }else if (end_date != null){
      url += `?end_date=${end_date}` 
    }

    return Api.get(url).catch(errorHandler)
  },

  getCharges(familyId, start_date=null, end_date=null){
    var url = `${resource}/${familyId}/charges`

    if (start_date != null && end_date != null){
      url += `?start_date=${start_date}&end_date=${end_date}` 

    }else if (start_date != null){
      url += `?start_date=${start_date}` 

    }else if (end_date != null){
      url += `?end_date=${end_date}` 
    }

    return Api.get(url).catch(errorHandler)
  },


}