// lessonLengthAPI.js

import Api from "./Api";
import {errorHandler} from "./Api"

const resource = "/lesson/lengths";

export default{
  get(){
    return Api.get(`${resource}`).catch(errorHandler)
  },

  getLessonLength(lessonLengthId){
    return Api.get(`${resource}/${lessonLengthId}`).catch(errorHandler)
  },

  createLessonLength(payload){
    return Api.post(`${resource}`, payload).catch(errorHandler)
  },

  updateLessonLength(payload){
    return Api.put(`${resource}/${payload.id}`, payload).catch(errorHandler)
  },

  deleteLessonLength(lessonLengthId){
    return Api.delete(`${resource}/${lessonLengthId}`).catch(errorHandler)
  },

  
}