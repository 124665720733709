// ApiFactory.js
import authApi from "./authApi";
import lessonLengthApi from "./lessonLengthAPI";
import lessonPriceApi from "./lessonPriceAPI";
import discountApi from "./discountAPI";
import studentsApi from "./studentsAPI";
import familyApi from "./familyAPI";
import lessonsAPI from "./lessonsAPI";
import recitalsAPI from "./recitalAPI"
import paymentsAPI from "./paymentsAPI";
import awardsAPI from "./awardsAPI";

const endpoints = {
  auth: authApi,

  lesson_length: lessonLengthApi,
  lesson_price: lessonPriceApi,
  lessons: lessonsAPI,
  discount: discountApi,
  students: studentsApi,
  family: familyApi,
  recitals: recitalsAPI,
  payments: paymentsAPI,
  awards: awardsAPI
}

export const ApiFactory = {
  get: name => endpoints[name]
}

