// apiDiscount.js

import Api from "./Api";
import {errorHandler} from "./Api"

const resource = "/discounts";

export default{
  get(){
    return Api.get(`${resource}`).catch(errorHandler)
  },

  getDiscount(discountId){
    return Api.get(`${resource}/${discountId}`).catch(errorHandler)
  },

  createDiscount(payload){
    return Api.post(`${resource}`, payload).catch(errorHandler)
  },

  updateDiscount(payload){
    return Api.put(`${resource}/${payload.id}`, payload).catch(errorHandler)
  },

  deleteDiscount(discountId){
    return Api.delete(`${resource}/${discountId}`).catch(errorHandler)
  },

}