// api.js

import axios from "axios";
import store from '../store'


const baseDomain = "";
const baseURL = `${baseDomain}/api`

var axios_instance = axios.create({
  baseURL: baseURL,
  // For auth
  headers: {
    Authorization: {
      toString () {
        return `Bearer ${store.getters.accessToken}`
      }
    },
    "Content-Type": "application/json"
  },
});

axios_instance.interceptors.response.use(response => response, (error) => {
  const originalRequest = error.config;
  if (!store.getters.refreshValid){
    return Promise.reject(error);
  }
  else if ((error.response.status == 401 || error.response.status == 403) && !originalRequest._retry) {
    return new Promise((resolve, reject) => {
      originalRequest._retry = true;
      store.dispatch('refresh').then(success => {
        if (success) {
            originalRequest.baseURL = ""; // Fix for malformed URLs
            return resolve(axios_instance.request(originalRequest));
        }else{
          return reject(error);
        }
      });
    });
  }else{
    return Promise.reject(error);
  }
});


export default axios_instance

export function errorHandler(err){
  if (err.response != undefined){
//    console.warn(err.response.data);
    store.commit('addError', err.response.data)
  }else{
//    console.warn(err);
    store.commit('addError', err)
  }
  
}