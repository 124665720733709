// studentAPI.js

import Api from "./Api";
import {errorHandler} from "./Api"

const resource = "/students";

export default{
  getAll(){
    var url = `${resource}`
    return Api.get(url).catch(errorHandler)
  },

  getNoFamily(){
    var url = `${resource}/nofamily`
    return Api.get(url).catch(errorHandler)
  },

  getList(includeInactive=false, year=null){
    var url = `${resource}/list`
    if (!includeInactive){
        url += '/active';
    }
    if (year != null){
      url += `/year/${year}`
    }
    return Api.get(url).catch(errorHandler)
  },

  getStudent(studentId){
    return Api.get(`${resource}/${studentId}`).catch(errorHandler)
  },

  createStudent(payload){
    return Api.post(`${resource}`, payload).catch(errorHandler)
  },

  updateStudent(payload){
    return Api.put(`${resource}/${payload.id}`, payload).catch(errorHandler)
  },

  deleteStudent(studentId){
    return Api.delete(`${resource}/${studentId}`).catch(errorHandler)
  },

  async getLessons(studentId, start_date=null, end_date=null){
    var url = `${resource}/${studentId}/lessons`
    if (start_date != null && end_date != null){
      url += `?start_date=${start_date}&end_date=${end_date}`;
    }else if (start_date == null){
      url += `?end_date=${end_date}`;
    }else if (end_date == null){
      url += `?start_date=${start_date}`;
    }

    return Api.get(url).catch(errorHandler);
  },

  async getSimpleFor(studentList=[]){
    var url = `${resource}/simple/for?students=`

    if (studentList.length > 0){
      url += studentList[0]
    }

    if (studentList.length > 1){
      for (var i=1; i < studentList.length; i++){
        url += "," + studentList[i]
      }
    }
    return Api.get(url).catch(errorHandler);
  },

  async getBalances(studentId, start_date=null, end_date=null){
    var url = `${resource}/${studentId}/balances`
    
    if (start_date != null && end_date != null){
      url += `?start_date=${start_date}&end_date=${end_date}` 

    }else if (start_date != null){
      url += `?start_date=${start_date}` 

    }else if (end_date != null){
      url += `?end_date=${end_date}` 
    }

    return Api.get(url).catch(errorHandler)
  },

  async getPayments(studentId, start_date=null, end_date=null){
    var url = `${resource}/${studentId}/payments`

    if (start_date != null && end_date != null){
      url += `?start_date=${start_date}&end_date=${end_date}` 

    }else if (start_date != null){
      url += `?start_date=${start_date}` 

    }else if (end_date != null){
      url += `?end_date=${end_date}` 
    }

    return Api.get(url).catch(errorHandler)
  },

  async getCharges(studentId, start_date=null, end_date=null){
    var url = `${resource}/${studentId}/charges`

    if (start_date != null && end_date != null){
      url += `?start_date=${start_date}&end_date=${end_date}` 

    }else if (start_date != null){
      url += `?start_date=${start_date}` 

    }else if (end_date != null){
      url += `?end_date=${end_date}` 
    }

    return Api.get(url).catch(errorHandler)
  },


  async getMinutes(studentId, start_date=null){
    var url = `${resource}/${studentId}/minutes`

    if (start_date != null){
      url += `?start_date=${start_date}` 

    }
    return Api.get(url).catch(errorHandler)
  },

  async getMinutesFor(studentList=[]){
    var url = `${resource}/for/minutes?students=`

    if (studentList.length > 0){
      url += studentList[0]
    }

    if (studentList.length > 1){
      for (var i=1; i < studentList.length; i++){
        url += "," + studentList[i]
      }
    }
    return Api.get(url).catch(errorHandler);
  },
  

}