<template>

  <div>
  <b-navbar toggleable="lg" type="dark" variant="dark" v-if="this.$route.name != 'Kiosk'">
    <router-link to="/" class="navbar-brand">
      Pianocharts
     </router-link>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav  v-if="this.$route.name != 'Login' && this.$route.name != 'Kiosk'">
      <b-navbar-nav class="mr-auto">
        <b-nav-item to="/">
          <font-awesome-icon icon="home"/>
          Home
        </b-nav-item>

        <b-nav-item to="/settings">
          <font-awesome-icon icon="cog"/>
          Settings
        </b-nav-item>

        <b-nav-item-dropdown text="Charts" right>
          <b-dropdown-item href="/charts/attendance">
            <font-awesome-icon fixed-width icon="star"/>
            Attendance
          </b-dropdown-item>
          <b-dropdown-item href="/charts/payments">
            <font-awesome-icon fixed-width icon="dollar-sign"/>
            Payments
          </b-dropdown-item>
          <b-dropdown-item href="/charts/minutes">
            <font-awesome-icon fixed-width icon="clock"/>
            Minutes
          </b-dropdown-item>          
        </b-nav-item-dropdown>
        

        <b-nav-item-dropdown text="Financial" right>
          <b-dropdown-item to="/payments">
            <font-awesome-icon icon="money-check-alt" fixed-width/>
            Payments
          </b-dropdown-item>
          <b-dropdown-item to="/balances">
            <font-awesome-icon icon="file-invoice-dollar" fixed-width/>
            Balances
          </b-dropdown-item>
          <b-dropdown-item to="/reports">
            <font-awesome-icon icon="table" fixed-width/>
            Reports
          </b-dropdown-item>
        </b-nav-item-dropdown>
      
        <b-nav-item to="/students">
          <font-awesome-icon icon="users"/>
          Student Roster
        </b-nav-item>

        <b-nav-item to="/awards">
          <font-awesome-icon icon="medal"/>
          Awards
        </b-nav-item>
      
      </b-navbar-nav>

      <b-navbar-nav>
        <b-button v-on:click="logout" class="float-right">
            Logout
        </b-button>
      </b-navbar-nav>

    </b-collapse>
  </b-navbar>

  <div class="container">
    <div v-for="(msg, index) in messages" :key="index" class="row" >
      <b-alert :variant="getAlertClass(msg.level)" dismissible show class="my-1" style="width:100%">
        <b>{{ getAlertDescr(msg.level) }}</b> 
        <span v-if="msg.message.error != undefined">
          {{ msg.message.error }}
        </span>
        <span v-else>
          {{ msg.message }}
        </span>

        <p v-if="msg.message.message != undefined" class="my-0">
          {{ msg.message.message }}
        </p>
      </b-alert>
    </div>
  </div>
  </div>
  
</template>
<script>
import { mapState } from 'vuex'

export default {
  methods: {
    async logout(){
      this.$store.dispatch('logout');
    },

    getAlertClass(level){
      if (level == 'error'){
        return 'danger'
      }else if(level == 'warning'){
        return 'warning'
      }else{
        return 'info'
      }
    },

    getAlertDescr(level){
      if (level == 'error'){
        return 'Error: '
      }else if(level == 'warning'){
        return 'Warning:'
      }else{
        return 'Info:'
      }
    }
      
  },

  computed: mapState({
    messages: state => state.messages,
  })

}
</script>