// apiPayment.js

import Api from "./Api";
import {errorHandler} from "./Api"

const resource = "/payments";

export default{
  async get(start_date=null, end_date=null){
    var url = `${resource}`;
    if (start_date != null && end_date != null){
      url += `?start_date=${start_date}&end_date=${end_date}`;

    }else if (start_date == null && end_date != null){
      url += `?end_date=${end_date}`;
    
    }else if (end_date == null && start_date != null){
      url += `?start_date=${start_date}`;
    }
    return Api.get(url).catch(errorHandler)
  },

  async getPayment(paymentId){
    return Api.get(`${resource}/${paymentId}`).catch(errorHandler)
  },

  async createPayment(payload){
    return Api.post(`${resource}`, payload).catch(errorHandler)
  },

  async updatePayment(payload){
    return Api.put(`${resource}/${payload.id}`, payload).catch(errorHandler)
  },

  async deletePayment(paymentId){
    return Api.delete(`${resource}/${paymentId}`).catch(errorHandler)
  },

}