// lessonsAPI.js

import Api from "./Api";
import {errorHandler} from "./Api"

const resource = "/lessons";

export default{
  get(){
    return Api.get(`${resource}`).catch(errorHandler)
  },

  getLesson(lessonId){
    return Api.get(`${resource}/${lessonId}`).catch(errorHandler)
  },

  createLesson(payload){
    return Api.post(`${resource}`, payload).catch(errorHandler)
  },

  updateLesson(payload){
    return Api.put(`${resource}/${payload.id}`, payload).catch(errorHandler)
  },

  deleteLesson(lessonId){
    return Api.delete(`${resource}/${lessonId}`).catch(errorHandler)
  },

  async getFor(studentList=[], start_date=null, end_date=null){
    var url = `${resource}/for?students=`

    if (studentList.length > 0){
      url += studentList[0]
    }

    if (studentList.length > 1){
      for (var i=1; i < studentList.length; i++){
        url += "," + studentList[i]
      }
    }

    if (start_date != null && end_date != null){
      url += `&start_date=${start_date}&end_date=${end_date}`;

    }else if (start_date == null){
      url += `&end_date=${end_date}`;
    
    }else if (end_date == null){
      url += `&start_date=${start_date}`;
    }

    return Api.get(url).catch(errorHandler);
  }

}