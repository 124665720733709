import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
      meta: {
        title: 'PianoCharts - Home',
        requiresAuth: true
      }
    },

    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
      meta: {
        title: 'Log In',
      },
    },

    {
      path: '/charts/attendance/:year?',
      name: 'Star Chart',
      component: () => import(/* webpackChunkName: "attendance-chart" */ './views/AttendanceChart.vue'),
      meta: {
        title: 'PianoCharts - Star Chart',
        requiresAuth: true
      }
    },

    {
      path: '/charts/payments/:year?',
      name: 'Payments Chart',
      component: () => import(/* webpackChunkName: "payments-chart" */ './views/PaymentsChart.vue'),
      meta: {
        title: 'PianoCharts - Payments Chart',
        requiresAuth: true
      }
    },

    {
      path: '/charts/minutes/',
      name: 'Minutes Chart',
      component: () => import(/* webpackChunkName: "minutes-chart" */ './views/MinutesChart.vue'),
      meta: {
        title: 'PianoCharts - Minutes Chart',
        requiresAuth: true
      }
    },

    {
      path: '/kiosk',
      name: 'Kiosk',
      component: () => import(/* webpackChunkName: "kiosk" */ './views/Kiosk.vue'),
      meta: {
        title: 'PianoCharts',
        kioskAuth: true
      }
    },

    {
      path: '/settings',
      name: 'Settings',
      component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue'),
      meta: {
        title: 'PianoCharts - Settings',
        requiresAuth: true
      }
    },

    {
      path: '/students',
      name: 'Students',
      component: () => import(/* webpackChunkName: "students" */ './views/Students.vue'),
      meta: {
        title: 'PianoCharts - Students',
        requiresAuth: true
      }
    },

    {
      path: '/payments',
      name: 'Payments',
      component: () => import(/* webpackChunkName: "payments" */ './views/Payments.vue'),
      meta: {
        title: 'PianoCharts - Payments',
        requiresAuth: true
      }
    },

    {
      path: '/balances',
      name: 'Balances',
      component: () => import(/* webpackChunkName: "balances" */ './views/Balances.vue'),
      meta: {
        title: 'PianoCharts - Balances',
        requiresAuth: true
      }
    },

    {
      path: '/reports',
      name: 'Reports',
      component: () => import(/* webpackChunkName: "balances" */ './views/Reports.vue'),
      meta: {
        title: 'Income Report',
        requiresAuth: true
      }
    },

    {
      path: '/awards',
      name: 'Awards',
      component: () => import(/* webpackChunkName: "balances" */ './views/Awards.vue'),
      meta: {
        title: 'Awards',
        requiresAuth: true
      }
    },

    {
      path: '*',
      redirect: '/login'
    },

  ],
});

