import Vue from 'vue';

import 'bootstrap/dist/css/bootstrap.css';

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas)
library.add(far)

import  Axios  from  'axios'
Vue.prototype.$http  =  Axios;
const  accessToken  =  localStorage.getItem('access_token')

if (accessToken) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] =  accessToken
}

import App from './App.vue';
import router from './router';
import store from './store'

router.beforeEach((to, from, next) => {
  store.commit('clearMessages');
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.accessValid && !store.getters.refreshValid || store.getters.accessParsed.user_claims.role == 'kiosk') {
      next({
          path: '/login',
          query: { nextUrl: to.fullPath }
      })
      return;
    }
  }

  if(to.matched.some(record => record.meta.kioskAuth)) {
    if (!store.getters.accessValid && !store.getters.refreshValid) {
      next({
          path: '/login',
         query: { nextUrl: to.fullPath, kiosk: true }
      })
      return;
    }
  }

  document.title = to.meta.title
  next()
})


Vue.use(BootstrapVue);
Vue.use(require('vue-moment'));


Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.config.performance = true;

var numeral = require("numeral");

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

