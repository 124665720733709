// apiCharge.js

import Api from "./Api";
import {errorHandler} from "./Api"

const resource = "/auth";

export default{

  refresh(refreshToken){
    var config = {
        headers: { Authorization: "Bearer " +  refreshToken}
    }
    return Api.post(`${resource}/refresh`, {}, config).then((response) => {
      return response.status == 200 ? response.data.access_token : null;
    })
  },

  async login(username, password){
    var payload = {
      "username": username,
      "password": password
    }

    return Api.post(`${resource}/login`, payload).then((response) => {
      return {
        response: response,
        message: ""
      };
    }).catch((err) => {
      if (err.response && (err.response.status == 401 || err.response.status == 400)){
        return {
          response: err.response,
          message: err.response.data.msg
        };
      }else{
        errorHandler(err);
        return {
          response: err.response,
          message: "Unknown Error"
        };
      }
      
    });
  },

  async kiosk_login(ident, token){
    var payload = {
      "ident": ident,
      "token": token
    }

    return Api.post(`${resource}/kiosk`, payload).then((response) => {
      return {
        response: response,
        message: ""
      };
    }).catch((err) => {
      if (err.response && (err.response.status == 401 || err.response.status == 400)){
        return {
          response: err.response,
          message: err.response.data.msg
        };
      }else{
        errorHandler(err);
        return {
          response: err.response,
          message: "Unknown Error"
        };
      }
      
    });
  }

}