// apiLessonPrice.js

import Api from "./Api";
import {errorHandler} from "./Api"

const resource = "/lesson/price";

export default{
  get(){
    return Api.get(`${resource}s`).catch(errorHandler)
  },

  getLessonPrice(lessonPriceId){
    return Api.get(`${resource}/${lessonPriceId}`).catch(errorHandler)
  },

  createLessonPrice(payload){
    return Api.post(`${resource}`, payload).catch(errorHandler)
  },

  updateLessonPrice(payload){
    return Api.put(`${resource}/${payload.id}`, payload).catch(errorHandler)
  },

  deleteLessonPrice(lessonPriceId){
    return Api.delete(`${resource}/${lessonPriceId}`).catch(errorHandler)
  },

}